* {
	padding: 0;
	margin: 0;
}

html,
body,
#fullheight {
	min-height: 100% !important;
	height: 100%;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
